import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

import home from '@/views/Home/index.vue';
import product from '@/views/product/index.vue';
import about from '@/views/about/index.vue';
import contact from '@/views/contact/index.vue';

let routes = [
  {
    path: '',
    redirect: '/index',
  },
  {
    path: '/index',
    name: 'home',
    meta: { auth: true, title: '首页', keepAlive: false },
    component: home,
    children: []
  },
  {
    path: '/product',
    name: 'product',
    meta: { auth: true, title: '产品展示', keepAlive: false },
    component: product,
    children: []
  },
  {
    path: '/about',
    name: 'about',
    meta: { auth: true, title: '品牌介绍', keepAlive: false },
    component: about,
    children: []
  },
  {
    path: '/contact',
    name: 'contact',
    meta: { auth: true, title: '联系我们', keepAlive: false },
    component: contact,
    children: []
  },
  
]

/**
 * 重定向，路由找不到则进入首页
 */
const router = new Router({
  base: '/index/',
  routes: routes
});

const originalPush = Router.prototype.push;
const originalReplace = Router.prototype.replace;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};
Router.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
};

router.beforeEach(async (to, from, next) => {
  // let code = Utils.getUrlVars()["code"];
  // let token = getToken();
  // if(code){
  //   store.dispatch('login',code).then((flag)=>{
  //     if(flag){
  //       next();
  //     }
  //   });
  // }else if(!token){
  //   reLogin();
  // }else{
     next();
  // }
});

export default router
