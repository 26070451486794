<template>
  <div class="index-header">
    <el-row>
      <el-col :span="6">
        <div class="index-logo">
          <img src="@/assets/logo2.png" alt="">
        </div>
      </el-col>
      <el-col :span="18">
        <el-menu :text-color="'#fff'" :router="false" background-color="transparent" :default-active="activeIndex"
          class="el-menu-demo" mode="horizontal" @select="handleSelect">

          <el-submenu index="2-1" popper-class="menuchild">
            <template slot="title">关于我们</template>
            <el-menu-item @click="gotoScroll('achievement')">成就</el-menu-item>
            <el-menu-item @click="gotoScroll('technology')">面料科技</el-menu-item>
          </el-submenu>
          <el-submenu index="2-2" popper-class="menuchild">
            <template slot="title">产品展示</template>
            <el-menu-item @click="gotoScroll('man')">男士</el-menu-item>
            <el-menu-item @click="gotoScroll('woman')">女士</el-menu-item>
          </el-submenu>
          <el-submenu index="2-3" popper-class="menuchild">
            <template #title>
              <!-- 在 title插槽中绑定一个事件 并阻止其冒泡，这样在点击这个区域时，
       el-sub-menu就不会展开/折叠了 -->

              <div class="subMenuTitle" style="display: inline;" @click.stop="gotoScroll('contact')">
                联系我们
              </div>
            </template>
            <!-- <template slot="title"> <span style="display: inline-block;" slot="title"
                @click.stop="gotoScroll('contact')">联系我们</span></template> -->
          </el-submenu>
          <!-- <el-menu-item index="contact">联系我们</el-menu-item> -->
        </el-menu>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'lonsheader',
  props: {
    msg: String
  },
  data() {
    return {
      activeIndex: 'home',
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      this.$router.push({ path: key })
    },
    gotoScroll(key) {
      this.$emit('goto', key);
    }


  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.index-header {
  position: absolute;
  left: 0;
  top: 70px;
  z-index: 999;
  width: 100%;
  background-color: transparent;
}

.index-logo {
  width: 120px;
  float: left;
  margin: 0 50px;
  height: 85px;
}

.index-logo img {
  width: 90px;
  margin: 10px;
}

.el-menu {
  background-color: transparent;
  padding-right: 0px;
}

.el-menu {
  background-color: transparent !important;
}

.el-menu:hover {
  background-color: transparent;
}

.el-menu.el-menu--horizontal {
  border-bottom: solid 0px #e6e6e6;
  margin-top: 10px;
  float: right;
  margin-right: 40px;
  color: #fff;
  text-align: left;
}

/deep/.el-submenu__title i {
  color: #fff;
  float: right;
}

.el-menu--horizontal>.el-submenu {
  float: left;
  margin-right: 90px;
  width: 140px;
  border-bottom: 1px solid #fff;
}

.el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: 0px solid #409EFF;
  color: #303133;
}

/deep/.el-menu--horizontal>.el-submenu .el-submenu__icon-arrow {

  margin-top: 9px;
}

/deep/.el-submenu__title {
  padding: 0 0px;
}

/deep/.el-submenu__title:hover {
  background-color: transparent !important;
}

/deep/.el-menu--horizontal>.el-submenu .el-submenu__title {
  height: 30px;
  line-height: 30px;
  border-bottom: 2px solid transparent;
  color: #909399;
}

.el-submenu:hover {
  background-color: transparent !important;
}

.menuchild {
  display: block !important;
}

.el-menu.el-menu--popup {
  background-color: rgba(255, 255, 255, .3) !important;
  width: 340px !important;
  min-width: 340px !important;
}

.el-submenu__title:hover {
  background-color: transparent !important;

}

/deep/.el-menu--horizontal.aaaa .el-menu {
  display: block;
}

.el-menu-item:hover {
  background-color: rgba(255, 255, 255, .1) !important;
}

.el-menu--horizontal .el-menu .el-menu-item,
.el-menu--horizontal .el-menu .el-submenu__title {
  background-color: transparent;
  width: 69%;
  margin: 0;
  text-indent: 10px
}
@media (max-width: 1440px){
  .el-menu-demo{
    display: none;
  }
  .index-header {
    
    top: 20px;
    z-index: 999;
    width: 100%;
    background-color: transparent;
}
.index-logo {
    width: 120px;
    float: left;
    margin: 0 10px;
    height: 85px;
}
}
</style>
