import headerItem from "@/components/headerItem.vue";
import footerItem from "@/components/footerItem.vue";

export default {
  name: "Layout",
  mixins: [],
  components: {
    headerItem,
    footerItem,
  },
  data() {
    return {
      activeIndex: "home",
      activeName: "man",
      activeNameMobile: "man",
      bannerWdith:'',
      bannerheight:'',
      productHeight:'',
    };
  },
  watch: {},
  created() {
    //获取屏幕宽度
    this.bannerWdith = window.innerWidth;
    this.bannerheight = this.bannerWdith * 1000 / 750;
    this.productHeight = this.bannerWdith * 1112 / 750;
    console.log("this.productheight:", this.productHeight)
  },
  mounted() {},
  destroyed() {},
  computed: {},
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClick() {
      // console.log(key, keyPath);
    },
    goto(key) {
      let scrollTo = "";
      if (key == "man" || key == "woman") {
        scrollTo = "product";
        this.activeName = key;
      } else {
        scrollTo = key;
      }
      this.$nextTick(() => {
        const anchorElement = document.getElementById(scrollTo);
        console.log("anchorElement:", anchorElement);

        if (anchorElement) {
          anchorElement.scrollIntoView({ behavior: "smooth" });
        }
      });
    },
  },
};
