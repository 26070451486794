import headerItem from "@/components/headerItem.vue";
import footerItem from "@/components/footerItem.vue";

export default {
  name: "Layout",
  mixins: [],
  components: {
    headerItem,footerItem
  },
  data() {
    return {
      activeIndex: 'home',
    };
  },
  watch: {
    
  },
  created() {
   
  },
  mounted() {},
  destroyed() {},
  computed: {
    
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    }
    
    
  },
};
