import headerItem from "@/components/headerItem.vue";
import footerItem from "@/components/footerItem.vue";
export default {
  name: "Layout",
  mixins: [],
  components: {
    headerItem,footerItem
  },
  data() {
    return {
      activeIndex: 'home',
      productList:[
       {
        img:'https://qiniu.ecsemir.com/temp/product1.png',
        url:'',
       },
       {
        img:'https://qiniu.ecsemir.com/temp/product2.png',
        url:'',
       },
       {
        img:'https://qiniu.ecsemir.com/temp/product3.png',
        url:'',
       },
       {
        img:'https://qiniu.ecsemir.com/temp/product4.png',
        url:'',
       },
       {
        img:'https://qiniu.ecsemir.com/temp/product5.png',
        url:'',
       },
      ]
    };
  },
  watch: {
    
  },
  created() {
   
  },
  mounted() {},
  destroyed() {},
  computed: {
    
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    }
    
    
  },
};
