<template>
  <div class="index-footer">
    <div class="footer-content-top">
      <div class="icon">
        <img src="@/assets/icon-huishou.jpg" alt="">
      </div>
      LONSDALE打造轻、坚固、耐用的面料，同时使其更具可持循环性。革命性的新材料强度是同等重量，可回收。<br />从我们的工程师到我们的仓库和办公室员工，我们都对户外活动充满热情。因此，我们的面料一直走专业高端路线。

    </div>
    <div class="footer-content-top-mobile">
      <img src="@/assets/footer-top.jpg" alt="">
    </div>
    <div class="footer-content-main" id="contact">
      <div class="footer-content-main-item">
        <el-row>
          <el-col :span="4">
            <div class="footer-content-main-item-logo"> <img src="@/assets/logo2.png" alt=""></div>

          </el-col>
          <el-col :span="6" class="footer-text">
            <span><i class="phone"><img src="@/assets/icon-phone.png" alt=""></i>400-997-9709</span>
            <span><i class="mail"><img src="@/assets/icon-mail.png" alt=""></i>品牌方邮箱：czz@lonsdale.cn </span>
          </el-col>
          <el-col :span="14" class="footer-text">
            <span><i class="company"><img src="@/assets/icon-address.png" alt=""></i>公司简介:泽辰和(上海)品牌管理有限公司为英国Lonsdale
              sports limited 正式授权的中国大陆(包括香港)的总经销商 </span>
            <span><i class="map"><img src="@/assets/icon-map.png" alt=""></i>地址:上海市嘉定区泰业街150弄7号3层J </span>
          </el-col>
        </el-row>
      </div>

    </div>
    <div class="footer-content-main-mobile">
      <img src="@/assets/footer.jpg" alt="">
    </div>
    <!-- <img src="@/assets/footbg.jpg" alt="" class="img"> -->
    <!-- <div class="footer-copyright">
        <div class="footer-copyright-text">Copyright@2024 LONSDALE All Rights Reserved.</div>
        <div class="footer-contact">关注我们<img src="@/assets/footer1.png" alt=""><img src="@/assets/footer2.png" alt=""></div>
      </div> -->
  </div>
</template>

<script>
export default {
  name: 'lonsFooter',
  props: {
    msg: String
  },
  data() {
    return {
      activeIndex: 'home',
    };
  },
  methods: {



  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.index-footer {
  width: 100%;
  margin: 30px auto 0;
}

.footer-content {
  width: 100%;
  height: 895px;

}

.footer-content img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.footer-copyright {
  width: 1200px;
  height: 100px;
  font-size: 12px;
  padding: 30px 0 0 0;
  border-top: 1px solid #ccc;
  margin: 100px auto 0;
  text-align: left;
}

.footer-copyright-text {
  float: left;
  width: 50%;
}

.footer-contact {
  float: right;
  width: 50%;
  text-align: right;
}

.footer-contact img {
  vertical-align: middle;
  margin: 0 0 0 10px;
}

.footer-content-top {
  height: 100px;
  text-align: right;
  line-height: 32px;
  padding-right: 40px;
  padding-top: 100px;
  font-size: 18px;
  display: block;
}
.footer-content-top-mobile{
 display: none;
}
.index-footer .img {
  width: 100%;
}

.footer-content-top .icon {
  width: 57px;
  height: 53px;
  position: absolute;
  left: 40px;
}

.footer-content-main {
  width: 100%;
  height: 897px;
  margin: 0 auto;
  background: url("@/assets/footbg.jpg") no-repeat 50% 50%;
  position: relative;
  display: block;
}
.footer-content-main-mobile{
  display: none;
}
.footer-content-main-item {
  width: 100%;
  height: 150px;
  text-align: left;
  position: absolute;
  left: 0;
  bottom: 0;
  color: #fff;
}

.footer-content-main-item-logo {
  width: 90px;
  margin: 20px 20px 20px 40px;
}

.footer-content-main-item-logo img {
  width: 70px;
  opacity: .7;
}

.footer-text span {
  color: #f5f5f5;
  opacity: 0.7;
  display: inline-block;
  width: 100%;
  height: 40px;
  line-height: 40px;
  position: relative;
  text-indent: 30px;
}

.footer-text span i {
  display: inline-block;
  width: 28px;
  height: 28px;
  text-align: center;
  position: absolute;
  left: 0;
  top: 4px;
  text-indent: 0;
}
@media (max-width: 1440px){
  .footer-content-main {
  
  display: none;
}
.footer-content-main-mobile{
  display: block;
}
.footer-content-main-mobile img{
  width: 100%;
}
.footer-content-top {  
  display: none;
}
.footer-content-top-mobile{
 display: block;
}
.footer-content-top-mobile img{
  width: 100%;
}
}
</style>
